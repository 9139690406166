<template>
    <transition name="slide-up">
        <div v-show="show" class="sheet">
            <div class="header">
                <div class="title spoqa-f-medium f-24 c-black" v-html="`필터`" />
                <i @click="$emit('close')" class="material-icons">close</i>
            </div>
            <div class="age">
                <Slider
                    v-if="ageValue.length"
                    :title="'나이'"
                    :range="ageValue"
                    :unit="'세'"
                    :slider-options="ageSliderOptions"
                    :key="ageSliderKey"
                    @change="onChangeAgeRange"
                />
            </div>
            <div class="content">
                <div class="title w-100 m-b-12" v-html="$translate('STATE')" />
                <div
                    class="distance-button"
                    :class="{ clicked: btn.clicked }"
                    v-for="btn in distance"
                    v-html="btn.name"
                    :key="btn.id"
                    @click="onClickState(btn.id)"
                />
            </div>
            <div class="marriage m-b-28">
                <div class="title" v-html="$translate('MARRY_TYPE')" />
                <div class="marriage-btns">
                    <div
                        class="btn-brd"
                        :class="{ clicked: btn.clicked }"
                        v-for="btn in marriage"
                        :key="btn.id"
                        v-html="btn.name"
                        @click="onClickMarry(btn.id)"
                    />
                </div>
            </div>
            <div class="two-btns">
                <div class="refresh-btn" @click="onClickRefresh">
                    <i class="material-icons m-r-8">refresh</i>
                    <span>초기화</span>
                </div>
                <div class="distance-btn" @click="onClickApply">
                    <span>결과 보기</span>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ModalFeedFilter',
    data: () => ({
        show: false,
        distance: [],
        state_ids: [],
        ageValue: [],
        ageSliderOptions: {
            min: 20,
            max: 100,
            tooltip: 'none',
        },
        ageSliderKey: 0,
        marriage: [],
    }),
    props: ['options'],
    mounted() {
        setTimeout(() => {
            this.show = true
        })
        this.init()
    },
    methods: {
        init() {
            this.distance = this.options.filterOptions.distance.map(distance => ({
                ...distance,
                clicked: distance.clicked ? distance.clicked : false,
            }))
            this.marriage = this.options.filterOptions.marriage.map(marriage => ({
                ...marriage,
                clicked: marriage.clicked ? marriage.clicked : false,
            }))
            this.ageValue = this.options.filterOptions.age.length ? this.options.filterOptions.age : [20, 100]
        },
        onChangeAgeRange(range) {
            this.ageValue = range
        },
        onClickState(id) {
            this.distance = this.distance.map(f => {
                if (f.id === id) {
                    return {
                        ...f,
                        clicked: !f.clicked,
                    }
                } else return f
            })
        },
        onClickMarry(id) {
            this.marriage = this.marriage.map(m => {
                if (m.id === id) {
                    return {
                        ...m,
                        clicked: !m.clicked,
                    }
                } else return m
            })
        },
        onClickRefresh() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'FilterPage_Click_Reset',
                },
            })
            this.distance = this.distance.map(f => ({
                ...f,
                clicked: false,
            }))
            this.ageValue = [20, 100]
            this.marriage = this.marriage.map(m => ({
                ...m,
                clicked: false,
            }))
            this.ageSliderKey = !this.ageSliderKey
        },
        onClickApply() {
            let arr = []
            const firebaseEvent = []
            this.distance.forEach(f => {
                if (f.clicked) {
                    arr = arr.concat(f.id)
                    firebaseEvent.push(f.id)
                }
            })
            const marryClicked = this.marriage.filter(m => m.clicked)
            const marryType = [0, 2].includes(marryClicked.length) ? null : marryClicked[0].id === 1 ? 0 : 1

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'FilterPage_Click_Activate',
                    option: {
                        label: firebaseEvent,
                    },
                },
            })
            this.$emit('close', {
                stateId: arr,
                marryType: marryType,
                filterOptions: {
                    distance: this.distance,
                    age: this.ageValue,
                    marriage: this.marriage,
                },
            })
        },
    },
}
</script>

<style scoped lang="scss">
.sheet {
    width: 100vw;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 12px 12px 0 0;
    padding: 0;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 20px;
}
.title {
    font-size: 14px;
    color: $grey-08 !important;
    @include f-medium;
}
::v-deep .range > .title {
    @extend .title;
}
.content {
    display: flex;
    flex-flow: row wrap;
    padding: 0 16px;
    margin-bottom: 16px;
    margin-top: 28px;

    .distance-button {
        display: flex;
        align-items: center;
        padding: 8px 14px;
        border-radius: 12px;
        border: 1px solid $grey-03;
        margin-right: 8px;
        margin-bottom: 8px;
        font-size: 14px;
        color: $grey-06;

        &.clicked {
            border: 1px solid $purple-primary;
            color: $purple-primary;
            box-shadow: 0 0 4px 0 rgba(84, 72, 203, 0.15);
            @include f-bold;
        }
    }
}
.marriage {
    padding: 0 16px;

    .marriage-btns {
        @include between;
        flex: 0 0 100%;
        margin-top: 12px;

        .btn-brd {
            @include center;
            border: 1px solid $grey-03;
            color: $grey-06;
            width: 50%;
            padding: 8px 0;
            font-size: 14px;

            &:nth-child(1) {
                margin-right: 8px;
            }
            &.clicked {
                border: 1px solid $purple-primary;
                color: $purple-primary;
                box-shadow: 0 0 4px 0 rgba(84, 72, 203, 0.15);
                @include f-bold;
            }
        }
    }
}
.two-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 16px;
    border-top: 1px solid $grey-02;

    .button {
        height: 100%;
        border-radius: 12px;
        @include center;
    }

    .refresh-btn {
        width: 33%;
        background: $grey-02;
        color: $grey-09;
        @extend .button;

        i {
            font-size: 20px;
        }
    }
    .distance-btn {
        width: 65%;
        background: $purple-primary;
        color: white;
        @include f-medium;
        @extend .button;
    }
}
</style>
